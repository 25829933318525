const $ = require('jquery');
console.log('hi dom');

import 'lazysizes';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import Flickity from 'flickity';
import 'flickity-as-nav-for';
import 'flickity-fullscreen';

document.addEventListener("DOMContentLoaded", () => {

    window.lazySizesConfig = window.lazySizesConfig || {};

    window.lazySizesConfig.expand = 250; //default 360-500
    lazySizesConfig.expFactor = 1.5; //default: 1.7

    function getUrlParameter(sParam){
        let sPageURL = window.location.search.substring(1);
        let sURLVariables = sPageURL.split('&');
        for (let i = 0; i < sURLVariables.length; i++)
        {
            let sParameterName = sURLVariables[i].split('=');
            if (sParameterName[0] == sParam)
            {
                return sParameterName[1];
            }
        }
    }

    // Refresh product name hidden field
    if ($("input[name='productName']").length > 0) {
        let requestId = getUrlParameter('requestId');
        if (requestId) {
            $(() => {
                $.ajax({
                    url: "/dynamicProductName?requestId=" + requestId,
                    success: (response) => {
                        $("input[name='productName']").val(response);
                    }
                });
            });
        }
    }

    // Refresh form tokens
    if ($("input[name='" + csrfTokenName + "']").length > 0) {
        $(() => {
            $.ajax({
                url: "/dynamicForm",
                success: (response) => {
                    $("input[name='" + csrfTokenName + "']").val(response);
                }
            });
        });
    }

    // responsive images
    // class ResponsiveBackgroundImage {
    //
    //     constructor(element) {
    //         this.element = element;
    //         this.img = element.querySelector('img');
    //         this.src = '';
    //
    //         this.img.addEventListener('load', () => {
    //             this.update();
    //         });
    //
    //         if (this.img.complete) {
    //             this.update();
    //         }
    //     }
    //
    //     update() {
    //         let src = typeof this.img.currentSrc !== 'undefined' ? this.img.currentSrc : this.img.src;
    //         if (this.src !== src) {
    //             this.src = src;
    //             this.element.style.backgroundImage = 'url("' + this.src + '")';
    //             this.element.classList.add('img-loaded');
    //
    //         }
    //     }
    // }
    //
    // let elements = document.querySelectorAll('[data-responsive-background-image]');
    // for (let i=0; i<elements.length; i++) {
    //     new ResponsiveBackgroundImage(elements[i]);
    // }

    // Get Home Path Blocks
    // const pathContainer = document.querySelector('.path-blocks');
    //
    // // // Resize Function
    // const resize = () => {
    //     let pathContainerTopOffset = null;
    //     let wHeight = window.innerHeight;
    //     let pathContainerDiff = null;
    //     if (window.innerWidth > 991) {
    //         pathContainerDiff = wHeight - 130;
    //     } else {
    //         pathContainerDiff = wHeight - 78;
    //     }
    //     // console.log(pathContainerTopOffset);
    //     // let wHeight = window.innerHeight;
    //     // let pathContainerDiff = wHeight - pathContainerTopOffset;
    //
    //     pathContainer.style.height = pathContainerDiff + 'px';
    //
    // };
    // // If path blocks exist call resize function
    // if (pathContainer) {
    //     resize();
    //     window.addEventListener('resize', () => {
    //         resize();
    //     });
    // }

    // Footer Position
    const siteFooter = document.querySelector('.site-footer');
    const mainContainer = document.getElementById('app');
    let footerHeight = siteFooter.clientHeight;
    mainContainer.style.paddingBottom = footerHeight + 'px';

    window.addEventListener('resize', () => {
        footerHeight = siteFooter.clientHeight;
        mainContainer.style.paddingBottom = footerHeight + 'px';
    });

    // Product Image Slider & Nav
    const productImageNavSlider = document.querySelector('.product-carousel-nav');
    const productImageSlider = document.querySelectorAll('.product-image-carousel');

    if (productImageNavSlider) {
        new Flickity(productImageNavSlider, {
            // options go here
            asNavFor: document.querySelector('.product-image-carousel'),
            cellAlign: 'left',
            percentPosition: false,
            draggable: true,
            wrapAround: false,
            groupCells: '100%',
            prevNextButtons: true,
            pageDots: false
        });
    }

    for (let i = 0; i < productImageSlider.length; i++) {
        new Flickity(productImageSlider[i], {
            // options go here
            cellAlign: 'center',
            dragThreshold: 10,
            contain: true,
            wrapAround: true,
            prevNextButtons: false,
            pageDots: false,
            fullscreen: true

        });
    }

    // Sticky Product Nav
    const productNav = document.getElementById('product-nav');
    const siteHeader = document.querySelector('.site-header');
    const productPageHeader = document.querySelector('.page-header');
    const productTop = document.querySelector('#product-top');
    if (productNav) {

        const productInfoWrap = document.getElementById('product-info-wrapper');
        const productDetailSections = document.querySelectorAll('.product-detail-section');
        const productRequestQuoteBtn = document.querySelector('#nav-request-quote');
        const firstProductNavItem = document.querySelector('.product-nav-list li');

        let productNavHeight = productNav.offsetHeight;
        let productNavOffset = productNav.offsetTop;

        firstProductNavItem.classList.add('product-nav-active-item');

        window.addEventListener('scroll', () => {
            if (window.pageYOffset >= productNavOffset) {
                productNav.classList.add("fixed-top");
                productInfoWrap.style.marginTop = productNavHeight + 'px';
                productRequestQuoteBtn.classList.add('show');
            } else {
                productNav.classList.remove("fixed-top");
                productInfoWrap.style.marginTop = '';
                productRequestQuoteBtn.classList.remove('show');
            }
            // console.log(window.pageYOffset);
            for (let i = 0; i < productDetailSections.length; i++) {
                if (productDetailSections[i].offsetTop <= window.pageYOffset) {
                    document.querySelector('.product-nav li.product-nav-active-item').classList.remove('product-nav-active-item');
                    document.querySelectorAll('.product-nav li')[i].classList.add('product-nav-active-item');
                }
            }
        });

        const productNavResize = () => {
            window.addEventListener('resize', () => {
                let siteHeaderHeight = siteHeader.offsetHeight;
                let productPageHeaderHeight = productPageHeader.offsetHeight;
                let productTopHeight = productTop.offsetHeight;
                productNavHeight = productNav.offsetHeight;
                productNavOffset = siteHeaderHeight + productPageHeaderHeight + productTopHeight;
            });
        };

        productNavResize();

    }

    // Researcher Nav
    const researcherNav = document.querySelector('.child-nav');
    const childNav = () => {

        var navwidth = 0;
        var morewidth = $('.child-nav .more-items').outerWidth(true);

        $('.child-nav > li:not(.more-items)').each(function() {
            navwidth += $(this).outerWidth( true );
        });

        var availablespace = $('nav').outerWidth(true) - morewidth;

        if (navwidth > availablespace) {

            var lastItem = $('.child-nav > li:not(.more-items)').last();
            lastItem.attr('data-width', lastItem.outerWidth(true));
            lastItem.prependTo($('.child-nav .more-items div ul'));
            childNav();

        } else {

            var firstMoreElement = $('.child-nav li.more-items li').first();
            if (navwidth + firstMoreElement.data('width') < availablespace) {
                firstMoreElement.insertBefore($('.child-nav .more-items'));
            }
        }

        if ($('.more-items li').length > 0) {
            $('.more-items').css('display','inline-block');
        } else {
            $('.more-items').css('display','none');
        }
    }

    if (researcherNav) {
        $(window).on('resize', function() {
            childNav();
        }).resize();
    }

    const headerSearch = document.getElementById('headerSearch');
    headerSearch.addEventListener('input', () => {
       if(headerSearch.value != '') {
           headerSearch.classList.add('hasValue');
       } else {
           headerSearch.classList.remove('hasValue');
       }
    });
});