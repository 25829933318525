import 'bootstrap';
import "./app.scss";

import Vue from "vue";
import Components from "./components";
window.jQuery = require('jquery');
import './js/adminbar.js';
import './js/script.js';

Vue.config.productionTip = false;

new Vue({
  el: "#app",
  delimiters: ["${", "}"]
});
